import React from "react";
import TopBackGround from "../../Atoms/Background";
import BreadCrumbs from "../../Atoms/BreadCrumbs/BreadCrumbs";
import ApplicationForm from "../../Organisms/ApplicationForm/ApplicationForm";
import { BodySection, MPContainer } from "../MainPage/MainPage.style";
import { BreadCWrapper } from "./Application.style";

const Application = () => {
  return (
    <MPContainer>
      <TopBackGround />
      <BreadCWrapper>
        <BreadCrumbs />
      </BreadCWrapper>
      <BodySection>
        <ApplicationForm />
      </BodySection>
    </MPContainer>
  );
};

export default Application;
