import React, { FC } from "react";
import { LoadingContainer, SpinX } from "./Loading.style";

interface LoadingProps {
  shouldPosition?: boolean;
}

const Loading: FC<LoadingProps> = ({ shouldPosition }) => {
  return (
    <LoadingContainer shouldPosition={shouldPosition}>
      <SpinX size="large" />
    </LoadingContainer>
  );
};

export default Loading;
