import React from "react";
import {
  CompanyOverview,
  HashTag,
  HBContainer,
  Header,
} from "./HeroBanner.style";

const HeroBanner = () => {
  return (
    <HBContainer>
      <Header>
        Join our team today <HashTag> #delphiChampions</HashTag>
      </Header>
      <CompanyOverview>
        We are a high-end jewelry retailer specialized in the design and sale of
        fine jewelry. We pride ourselves on our exquisite craftsmanship,
        attention to detail, and exceptional customer service. We seek highly
        motivated and experienced staff to join our team.
      </CompanyOverview>
    </HBContainer>
  );
};

export default HeroBanner;
