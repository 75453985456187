import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { GlobalStyle } from "./GSStyle";
import MainPage from "./components/Pages/MainPage/MainPage";
import Application from "./components/Pages/Application/Application";

function App() {
  return (
    <div style={{ padding: "0px" }}>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={MainPage} />
          <Route path="/application" Component={Application} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
