import styled from "styled-components";
import devices from "../../../constants/devices";

export const HBContainer = styled.div`
  padding: 10px 30px;

  @media ${devices.lg} {
    padding: 100px;
  }
`;

export const Header = styled.div`
  max-width: 300px;
  font-family: Montserrat;
  font-weight: 800;
  font-size: 20px;

  @media ${devices.md} {
    font-size: 30px;
    max-width: 500px;
  }

  @media ${devices.lg} {
    font-size: 70px;
    max-width: 800px;
  }
`;

export const HashTag = styled.span`
  font-family: Montserrat;
  font-weight: 800;
  color: #fd5631;
`;

export const CompanyOverview = styled.div`
  display: none;
  max-width: 400px;
  font-family: Maitree;
  font-size: 15px;
  margin-top: 10px;
  font-weight: 200;
  @media ${devices.md} {
    display: block;
    font-size: 15px;
    max-width: 400px;
    margin-top: 10px;
  }
  @media ${devices.xmd} {
    display: block;
    font-size: 20px;
    max-width: 500px;
    margin-top: 10px;
  }

  @media ${devices.lg} {
    font-size: 25px;
    max-width: 800px;
    display: block;
    margin-top: 20px;
  }
`;
