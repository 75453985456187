import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  LeftWrapper,
  MainContainer,
  RightWrapper,
} from "./ApplicationForm.style";

import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

export interface JobType {
  description: string;
  desiredSkills: string[];
  experience: string;
  jobCategory: string;
  jobDetails: string;
  jobRequirements: string;
  jobTitle: string;
  jobType: string;
  location: string;
  minQualification: string;
}

const ApplicationForm = () => {
  const [formKey, setFormKey] = useState<number>(Date.now());
  const location = useLocation();
  const { job }: { job: JobType } = location.state ?? {};
  return (
    <MainContainer>
      <LeftWrapper>
        <LeftPanel job={job} />
      </LeftWrapper>
      <RightWrapper>
        <RightPanel job={job} formKey={formKey} setFormKey={setFormKey} />
      </RightWrapper>
    </MainContainer>
  );
};

export default ApplicationForm;
