const xlg_ = "1920px";
const lg_ = "1440px";
const xmd_ = "1020px";
const md_ = "760px";
const sm_ = "370px";

const devices = {
  xlg: `(min-width: ${xlg_})`,
  lg: `(min-width: ${lg_})`,
  md: `(min-width: ${md_})`,
  xmd: `(min-width: ${xmd_})`,
  sm: `(min-width: ${sm_})`,
};

export default devices;
