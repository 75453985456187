import React from "react";
import { Breadcrumb } from "antd";

const BreadCrumbs = () => {
  return (
    <Breadcrumb
      items={[
        {
          title: <a href="/">Home</a>,
        },

        {
          title: "Application Form",
        },
      ]}
    />
  );
};

export default BreadCrumbs;
