import styled from "styled-components";
import devices from "../../../constants/devices";

export const JobSectionContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;

  @media ${devices.md} {
    grid-template-columns: auto auto;
    justify-content: space-between;
  }
  @media ${devices.lg} {
    grid-template-columns: auto auto auto auto;
    justify-content: space-between;
  }
`;

export const IndividualJobCardWrapper = styled.div`
  margin-top: 10px;

  @media ${devices.md} {
    margin-top: 10px;
  }
  @media ${devices.lg} {
    margin-top: 40px;
  }
`;
