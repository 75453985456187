import styled from "styled-components";
import { Spin } from "antd";

export const LoadingContainer = styled.div<{
  shouldPosition: boolean | undefined;
}>`
  text-align: ${({ shouldPosition }) => (shouldPosition ? "null" : "center")};
  margin-top: ${({ shouldPosition }) => (shouldPosition ? "0" : "50px")}; ;
`;

export const SpinX = styled(Spin)`
  .ant-spin-dot-item {
    background-color: #fd5631;
  }
`;
