import styled from "styled-components";
import { HiOutlineLocationMarker } from "react-icons/hi";
import devices from "../../../constants/devices";

export const Container = styled.div`
  cursor: pointer;
  height: 457px;
  width: 310px;
  padding: 20px;
  border: 1px solid rgb(192, 202, 213);
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 2px 0px,
    rgba(0, 0, 0, 0.16) 0px 2px 8px 0px;
  @media ${devices.md} {
    width: 350px;
    height: 487px;
  }
  @media ${devices.xmd} {
    width: 450px;
    height: 487px;
  }
  @media ${devices.lg} {
    width: 400px;
    height: 487px;
  }
`;

export const Category = styled.div`
  margin-top: 20px;
  margin-bottom: 14px;
  font-family: Montserrat;
  color: #fd5631;
  font-size: 16px;
`;

export const Title = styled.div`
  font-family: Maitree;
  font-weight: 700;
  font-size: 18px;
`;
export const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 8px;
  color: grey;
  margin-bottom: 14px;
`;

export const Icon = styled(HiOutlineLocationMarker)`
  color: grey;
`;

export const LocationName = styled.div``;
export const LocationIcon = styled.div``;

export const Description = styled.div`
  font-family: Maitree;
  margin-bottom: 24px;
  font-size: 14px;
  height: 228px;
  display: block;
  display: -webkit-box;
  max-width: 400px;
  /* height: 80%; */
  margin: 0 auto;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${devices.md} {
    margin-bottom: 14px;
    font-size: 16px;
    height: 258px;
  }
  @media ${devices.xmd} {
    margin-bottom: 14px;
    font-size: 16px;
    height: 258px;
  }
  @media ${devices.lg} {
    margin-bottom: 14px;
    font-size: 16px;
    height: 258px;
  }
`;

export const TagWrapper = styled.div`
  display: inline-block;
  padding: 5px 10px;
  background: #f5f4f8;
  border-radius: 5px;
  font-family: Maitree;
`;

export const Tag = styled.div``;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ApplyButton = styled.div`
  cursor: pointer;
  background: #314a8d;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
  color: #fff;
  font-family: Maitree;
`;
