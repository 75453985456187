import styled from "styled-components";
import devices from "../../../constants/devices";
import { Input, Checkbox, Button } from "antd";

const { TextArea } = Input;

export const MainContainer = styled.div`
  display: block;

  @media ${devices.md} {
    display: flex;
    grid-gap: 40px;
  }
`;

export const LeftWrapper = styled.div`
  width: 100%;
  @media ${devices.md} {
    width: 50%;
  }
`;

export const RightWrapper = styled.div`
  width: 50%;
`;

export const LPContainer = styled.div``;
export const LTitle = styled.div`
  font-family: Montserrat;
  font-size: 33px;
  font-weight: 700;
  margin-bottom: 40px;
`;
export const LSectionWrapper = styled.div`
  margin-bottom: 16px;
`;
export const LSectionHeader = styled.div`
  font-family: Maitree;
  font-size: 23px;
  font-weight: 700;
`;
export const LSectionDetails = styled.div`
  font-family: Maitree;
  font-size: 20px;
  font-weight: 200;
`;

export const RPContainer = styled.div``;

export const Wrapper = styled.div`
  margin-bottom: 16px;
`;

export const InputText = styled(Input)`
  width: 100%;
  padding: 10px 15px;
`;

export const TextAreaC = styled(TextArea)``;

export const CheckBoxWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const AgreeText = styled.div`
  font-family: Maitree;
  font-size: 15px;
  font-weight: 400;
`;

export const CheckboxC = styled(Checkbox)``;

export const ButtonC = styled(Button)`
  background: #fd5631;
  color: #fff;
  padding: 0px 35px;
  height: 40px;
  font-family: Maitree;
  font-size: 18px;
  font-weight: 800;
`;

export const SkillBadge = styled.div`
  margin-top: 10px;
  display: inline-block;
  padding: 5px 15px;
  background: #feeae6;
  margin-left: 20px;
  :first-child {
    margin-left: 0;
  }
`;
