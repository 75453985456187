import React from "react";
import HeroBanner from "../../Organisms/HeroBanner/HeroBanner";
import img from "../../../backgroundp1.jpg";

import { BackgroundWrapper } from "../../Pages/MainPage/MainPage.style";

const TopBackGround = () => {
  return (
    <BackgroundWrapper style={{ backgroundImage: `url(${img})` }}>
      <HeroBanner />
    </BackgroundWrapper>
  );
};

export default TopBackGround;
