import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  ApplyButton,
  Category,
  Container,
  Description,
  FooterWrapper,
  Icon,
  LocationIcon,
  LocationName,
  LocationWrapper,
  Tag,
  TagWrapper,
  Title,
} from "./JobCard.style";

interface JobCardProps {
  job: {
    id: string;
    jobTitle: string;
    location: string;
    description: string;
    jobType: string;
    experienceYearsRequired: string;
    jobCategory: string;
    jobDetails: string;
    jobRequirements: string;
    minQualification: string;
    desiredSkills: string[];
  };
}

const JobCard: FC<JobCardProps> = ({ job }) => {
  const navigate = useNavigate();
  return (
    <Container
      onClick={() =>
        navigate("/application", {
          state: {
            job,
          },
        })
      }
    >
      <Category> {job.jobCategory.toUpperCase()} </Category>
      <Title> {job.jobTitle}</Title>
      <LocationWrapper>
        <LocationIcon>
          <Icon />
        </LocationIcon>
        <LocationName> {job.location} </LocationName>
      </LocationWrapper>
      <Description> {job.description} </Description>
      <FooterWrapper>
        <TagWrapper>
          <Tag> {job.jobType} </Tag>
        </TagWrapper>
        <ApplyButton
          onClick={() =>
            navigate("/application", {
              state: {
                job,
              },
            })
          }
        >
          {" "}
          Apply{" "}
        </ApplyButton>
      </FooterWrapper>
    </Container>
  );
};

export default JobCard;
