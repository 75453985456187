import React, { useEffect, useState, useCallback } from "react";

interface JobsType {
  id: string;
  location: string;
  jobTitle: string;
  jobDetails: string;
  description: string;
  experienceYearsRequired: string;
  jobCategory: string;
  jobType: string;
  minQualification: string;
  desiredSkills: string[];
  jobRequirements: string;
}

const useFetchJobs = () => {
  const [jobs, setJobs] = useState<JobsType[] | undefined>();

  const fetchJobs = useCallback(async () => {
    await fetch(`${process.env.REACT_APP_SERVER}fetchjobs`, {
      method: "GET",
      headers: {},
    }).then((res) => res.json().then((rex) => setJobs(rex.items)));
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  return { jobs };
};

export default useFetchJobs;
