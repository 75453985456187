import React, { useState, FC } from "react";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import { FileUploadContainer } from "./FileUpload.style";

const { Dragger } = Upload;

interface FileUploadProps {
  resetUpload: number;
  setCv: React.Dispatch<any>;
}

const FileUpload: FC<FileUploadProps> = ({ resetUpload, setCv }) => {
  const [filex, setFilex] = useState<any>();

  const [shouldUpload, setShouldUpload] = useState<boolean>(false);

  const props: UploadProps = {
    name: "file",

    async customRequest(file) {
      if (file && shouldUpload) {
        setCv(file.file);
        file.onSuccess!("File Uploaded");
      }
    },

    beforeUpload(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("File size greater than 2mb");
        setShouldUpload(false);
      }
      const fileNameArr = file.name.split(".");
      const resolvedFileName = `${fileNameArr[0]}-${file.uid}.${fileNameArr[1]}`;
      setShouldUpload(true);
      setFilex({
        name: resolvedFileName,
        size: file.size,
      });
    },

    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
    onRemove(file) {
      setCv(undefined);
    },

    maxCount: 1,
    accept: ".docx, .pdf, .doc ",
  };

  return (
    <FileUploadContainer key={resetUpload}>
      <Dragger {...props} showUploadList style={{ height: "100%" }}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined style={{ color: "#fd5631" }} />
        </p>
        <p className="ant-upload-text">Upload CV/Resume</p>
      </Dragger>
    </FileUploadContainer>
  );
};

export default FileUpload;
