import useFetchJobs from "../../../hooks/useFetchJobs";
import JobCard from "../../Atoms/JobCard/JobCard";
import Loading from "../../Atoms/Loading/Loading";
import {
  JobSectionContainer,
  IndividualJobCardWrapper,
} from "./JobSection.style";

const JobSection = () => {
  const { jobs } = useFetchJobs();

  if (!jobs?.length) {
    return (
      <div>
        {" "}
        <Loading />{" "}
      </div>
    );
  }

  return (
    <JobSectionContainer>
      {jobs &&
        jobs?.length &&
        jobs?.map((item, i) => {
          return (
            <IndividualJobCardWrapper key={`JobSection-${i}`}>
              <JobCard job={item} />
            </IndividualJobCardWrapper>
          );
        })}
    </JobSectionContainer>
  );
};

export default JobSection;
