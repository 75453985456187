import TopBackGround from "../../Atoms/Background";
import JobSection from "../../Organisms/JobSection/JobSection";
import { BodySection, MPContainer } from "./MainPage.style";

const MainPage = () => {
  return (
    <MPContainer>
      <TopBackGround />
      <BodySection>
        <JobSection />
      </BodySection>
    </MPContainer>
  );
};

export default MainPage;
