import React, { FC } from "react";
import { JobType } from "./ApplicationForm";
import {
  LPContainer,
  LSectionDetails,
  LSectionHeader,
  LSectionWrapper,
  LTitle,
  SkillBadge,
} from "./ApplicationForm.style";

interface LeftPanelProps {
  job: JobType;
}

const LeftPanel: FC<LeftPanelProps> = ({ job }) => {
  return (
    <LPContainer>
      <LTitle> {job.jobTitle} </LTitle>
      <LSectionWrapper>
        <LSectionHeader> Location: </LSectionHeader>
        <LSectionDetails> {job.location} </LSectionDetails>
      </LSectionWrapper>
      <LSectionWrapper>
        <LSectionHeader> Job Description: </LSectionHeader>
        <LSectionDetails>{job.description}</LSectionDetails>
      </LSectionWrapper>
      <LSectionWrapper>
        <LSectionHeader> Job Details: </LSectionHeader>
        <LSectionDetails>{job.jobDetails}</LSectionDetails>
      </LSectionWrapper>

      <LSectionWrapper>
        <LSectionHeader> Job Requirements: </LSectionHeader>
        <LSectionDetails> {job.jobRequirements} </LSectionDetails>
      </LSectionWrapper>
      <LSectionWrapper>
        <LSectionHeader> Min Required Experience: </LSectionHeader>
        <LSectionDetails> {job.minQualification} </LSectionDetails>
      </LSectionWrapper>
      <LSectionWrapper>
        <LSectionHeader> Desired Skills: </LSectionHeader>
        <LSectionDetails>
          {" "}
          {job.desiredSkills.map((skill, i) => {
            return (
              <SkillBadge key={`SkillBadgeKey-${i}`}> {skill} </SkillBadge>
            );
          })}
        </LSectionDetails>
      </LSectionWrapper>
      <LSectionWrapper>
        <LSectionHeader> Job Type: </LSectionHeader>
        <LSectionDetails> {job.jobType} </LSectionDetails>
      </LSectionWrapper>
    </LPContainer>
  );
};

export default LeftPanel;
