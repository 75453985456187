import React, { FC, useState } from "react";
import FileUpload from "../../Atoms/FileUpload";
import * as EmailValidator from "email-validator";
import { message } from "antd";
import {
  EReCaptchaV2Size,
  EReCaptchaV2Theme,
  ReCaptchaProvider,
  ReCaptchaV2,
  TReCaptchaV2Callback,
  TReCaptchaV3RefreshToken,
} from "react-recaptcha-x";
import {
  AgreeText,
  ButtonC,
  CheckboxC,
  CheckBoxWrapper,
  InputText,
  LSectionHeader,
  RPContainer,
  TextAreaC,
  Wrapper,
} from "./ApplicationForm.style";
import { JobType } from "./ApplicationForm";
import Loading from "../../Atoms/Loading/Loading";

interface RightPanelProps {
  job: JobType;
  formKey: number;
  setFormKey: React.Dispatch<React.SetStateAction<number>>;
}

const RightPanel: FC<RightPanelProps> = ({ job, formKey, setFormKey }) => {
  const [fname, setFname] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [phone, setPhone] = useState<string | undefined>();
  const [location, setLocation] = useState<string | undefined>(job.location);
  const [coverLetter, setCoverLetter] = useState<string | undefined>();
  const [cv, setCV] = useState<any | undefined>();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [isCaptcha, setIsCaptcha] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const v2Callback: TReCaptchaV2Callback = (
    token: string | false | Error
  ): void => {
    if (typeof token === "string") {
      setIsCaptcha(true);
    } else if (typeof token === "boolean" && !token) {
    } else if (token instanceof Error) {
    }
  };

  const resetForm = () => {
    setIsCaptcha(false);
    setIsChecked(false);
    setCV(undefined);
    setCoverLetter(undefined);
    setPhone(undefined);
    setEmail(undefined);
    setFname(undefined);
  };

  const formVerify = async () => {
    if (fname && fname?.length > 2) {
      if (email && EmailValidator.validate(email)) {
        if (phone && phone?.length > 2) {
          if (cv) {
            if (isChecked) {
              if (isCaptcha) {
                return true;
              } else {
                message.error("Verification required");
                return false;
              }
            } else {
              message.error("Agreement required");
              return false;
            }
          } else {
            message.error("CV/Resume required");
            return false;
          }
        } else {
          message.error("Phone number is required");
          return false;
        }
      } else {
        message.error("Email is Invalid");
        return false;
      }
    } else {
      message.error("Full Name Required");
      return false;
    }
  };

  const submitHandler = async () => {
    setSubmitting(true);
    const result = await formVerify();
    if (result) {
      let formData = new FormData();
      const resultObj = {
        fname,
        email,
        phone,
        location,
        coverLetter: coverLetter ?? "",
        position: job.jobTitle,
      };
      formData.append("file", cv);
      formData.append("document", JSON.stringify(resultObj));

      fetch(`${process.env.REACT_APP_SERVER}joinus`, {
        method: "POST",
        body: formData,
        headers: {},
      }).then((res) => {
        res.json().then((rex) => {
          if (rex.mode === "success") {
            setFormKey(Date.now());
            resetForm();
            message.success(rex.msg);
            setSubmitting(false);
          } else {
            message.error(rex.msg);
            setSubmitting(false);
          }
        });
      });
    }
    setSubmitting(false);
  };

  return (
    <RPContainer key={formKey}>
      <ReCaptchaProvider
        siteKeyV2="6LdtyEslAAAAAM8IK1ryEukcGzyRBiii6_TkFdpk"
        langCode="en"
        hideV3Badge={false}
      >
        <Wrapper>
          <LSectionHeader> Apply for this position</LSectionHeader>
        </Wrapper>
        <Wrapper>
          <InputText
            placeholder="Full Name*"
            required
            value={fname}
            onChange={(e) => setFname(e.target.value)}
          />
        </Wrapper>
        <Wrapper>
          <InputText
            placeholder="Email*"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Wrapper>
        <Wrapper>
          <InputText
            placeholder="Phone*"
            required
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Wrapper>
        <Wrapper>
          <InputText
            placeholder="Location*"
            required
            disabled
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </Wrapper>
        <Wrapper>
          <TextAreaC
            placeholder="Cover Letter"
            rows={8}
            value={coverLetter}
            onChange={(e) => setCoverLetter(e.target.value)}
          />
        </Wrapper>
        <Wrapper>
          <FileUpload resetUpload={1} setCv={setCV} />
        </Wrapper>
        <CheckBoxWrapper>
          <CheckboxC
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
          >
            <AgreeText>
              By using the form I agree with the storage and handling of my data
              by this website
            </AgreeText>
          </CheckboxC>
        </CheckBoxWrapper>
        <Wrapper>
          <ReCaptchaV2
            callback={v2Callback}
            theme={EReCaptchaV2Theme.Light}
            size={EReCaptchaV2Size.Normal}
            id="my-id"
            data-test-id="my-test-id"
            tabindex={0}
          />
        </Wrapper>
        <Wrapper>
          {submitting ? (
            <Loading shouldPosition={true} />
          ) : (
            <ButtonC
              onClick={() => {
                setSubmitting(true);
                submitHandler();
              }}
            >
              Submit
            </ButtonC>
          )}
        </Wrapper>
      </ReCaptchaProvider>
    </RPContainer>
  );
};

export default RightPanel;
