import styled from "styled-components";
import devices from "../../../constants/devices";

export const BreadCWrapper = styled.div`
  padding: 5px 24px;
  font-family: Maitree;
  font-size: 23px;

  @media ${devices.md} {
    padding: 5px 24px;
  }
  @media ${devices.lg} {
    padding: 10px 74px;
  }
`;
