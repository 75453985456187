import styled from "styled-components";
import devices from "../../../constants/devices";

export const MPContainer = styled.div`
  padding: 0;
  margin: 0;
  body {
    padding: 0 !important;
  }
`;

export const BackgroundWrapper = styled.div`
  height: 130px;
  padding: 0;
  margin: 0;
  /* background-size: cover; */
  background-size: 100%;
  background-repeat: no-repeat;

  @media ${devices.md} {
    height: 260px;
  }
  @media ${devices.xmd} {
    height: 350px;
  }

  @media ${devices.lg} {
    height: 600px;
  }
`;

export const BodySection = styled.div`
  padding: 10px 24px;

  @media ${devices.md} {
    padding: 10px 24px;
  }
  @media ${devices.lg} {
    padding: 10px 74px;
  }
`;
